<template>
  <div id="app">
    <router-view class="router-view" id="id-router-view"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  created() {
    window.addEventListener('scroll', () => {
      this.$store.commit("SET_PAGE_SCROLL_TOP", document.documentElement.scrollTop || document.body.scrollTop)
    })
    this.$store.commit("SET_PAGE_WIDTH", window.innerWidth)
    window.addEventListener("resize", () => {
      this.$store.commit("SET_PAGE_WIDTH", window.innerWidth)
    }, false);
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
}

</style>
