const common = {
  state: {
    pageScrollTop: 0,
    pageWidth: 0,
    pageType: 'h5'
  },
  actions: {},
  mutations: {
    SET_PAGE_SCROLL_TOP: (state, pageScrollTop) => {
      state.pageScrollTop = pageScrollTop
    },
    SET_PAGE_WIDTH: (state, pageWidth) => {
      state.pageWidth = pageWidth
      if (pageWidth >= 1260) {
        state.pageType = 'pc'
      } else {
        state.pageType = 'h5'
      }
    },
  }
}
export default common
