

const routes = [
  {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home/HomeIndex.vue'),
      meta:{
      }
  },
  {
      path: '*',
      redirect: '/',
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history',
  routes,
  // scrollBehavior(to,from,saveTop){
  //     if(saveTop){
  //         return saveTop;
  //     }else{
  //         return {x:0,y:0}
  //     }
  // },
})
